import { createResponse } from '@lemonenergy/ssr-utils-node'
import type { LoaderFunction } from '@remix-run/node'
import type { ClientLoaderFunctionArgs } from '@remix-run/react'
import { Outlet, useLocation } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { motion } from 'framer-motion'

import { motionPageSlideInOutProps } from '~/components/PageAnimations'
import withClearCookies from '~/middlewares/withClearAllCookies'
import constants from '~/res/constants'
import { deprecatedCookieNames } from '~/res/constants/cookies.server'
import {
  withAuthorized,
  withAuthorizerRedirect,
} from '~/utils/auth.util/auth.middleware.server'
import WorkboxWindow from '~/WorkboxWindow'

export { ignoreDialogRevalidation as shouldRevalidate } from '~/revalidationRules'

export const loader: LoaderFunction = async (loaderArgs) => {
  const response = createResponse(loaderArgs)
  response.use(withClearCookies(deprecatedCookieNames))

  response.use(
    withAuthorized({
      authCookie: constants.cookies.default.authentication,
      authorizerFunctionName:
        constants.lambda.functionNames.auth.authorizationHandler,
    }),
  )
  response.use(withAuthorizerRedirect({ successUrl: '/' }))
  return response.handle()
}

export const clientLoader = async ({
  serverLoader,
}: ClientLoaderFunctionArgs) => {
  const serverData = await serverLoader()

  try {
    WorkboxWindow.getInstance().clearCache()
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        error: 'Failed to clear cache',
      },
    })
  }

  return serverData
}

clientLoader.hydrate = true

export default () => {
  const location = useLocation()

  return (
    <motion.div
      key={location.pathname}
      className="flex grow flex-col"
      {...motionPageSlideInOutProps}
    >
      <Outlet />
    </motion.div>
  )
}
